export default {
  namespaced: true,
  state: {
    bodyWidth: 1920,
    bodyHeight: 1080,
  },
  mutations: {
    SET_SIZE(state, { bodyWidth, bodyHeight }) {
      state.bodyWidth = bodyWidth;
      state.bodyHeight = bodyHeight;
    },
  },
  actions: {
    setSize({ commit }, data) {
      commit("SET_SIZE", data);
    },
  },
};
