<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      const bodyWidth = document.body.getBoundingClientRect().width;
      const bodyHeight = parseInt(bodyWidth * (9 / 16));
      this.$store.dispatch("window/setSize", { bodyWidth, bodyHeight });
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
